import * as React from 'react';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import { graphql, HeadFC } from 'gatsby';
import NewLayout from 'components/NewLayout';
import ProductOverview from 'components/pages/productOverview';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';

const pageUrl = `${PREFIX_CANONICAL_URL}/construction-management-software`;

const IndexPage = () => {
  return (
    <LanguageProvider messages={translation}>
      <NewLayout headerStyle="light">
        <ProductOverview pageUrl={pageUrl} />
      </NewLayout>
    </LanguageProvider>
  );
};
export default IndexPage;

export const Head = ({
  data,
}: {
  data: { allStrapiProductOverview: { nodes: PageSEOType[] } };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiProductOverview.nodes[0]}
      canonicalURL={pageUrl}
    />
  );
};

export const query = graphql`
  query {
    allStrapiProductOverview {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaTitle
          metaDescription
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
