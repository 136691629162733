import Link from 'components/Link';
import React from 'react';
import tw from 'twin.macro';
import { ProductOverviewBannerType } from './types';

interface ProductBannerProps extends ProductOverviewBannerType {}

const ComponentWrapper = tw.div`
  flex flex-col md:flex-row gap-6 lg:gap-4 md:gap-8 justify-between z-1 text-white px-[30px] py-[30px] md:py-[27px] lg:px-0 md:h-fit
`;
const ButtonWrapper = tw.div`
  flex gap-3 items-center justify-center self-start lg:self-center border border-hubble_blue px-3 py-2 bg-white hover:bg-hubble_blue
`;
const StyledLink = tw(Link)`
  text-[14px] md:text-[16px] font-[500] text-hubble_blue group-hover:text-white
`;
const Heading = tw.p`
  text-[20px] lg:text-[18px] font-[500] md:font-[400] mb-2 lg:mb-0
`;
const Description = tw.p`
  text-[14px] mb-0
`;

const ProductBanner = ({
  header,
  description,
  btnLabel,
  btnLink,
}: ProductBannerProps) => {
  return (
    <ComponentWrapper>
      <div>
        <Heading>{header}</Heading>
        <Description>{description}</Description>
      </div>
      <ButtonWrapper className="group">
        <StyledLink to={btnLink}>{btnLabel}</StyledLink>
        <span className="fa-light fa-arrow-right text-hubble_blue group-hover:text-white" />
      </ButtonWrapper>
    </ComponentWrapper>
  );
};

export default ProductBanner;
