import React from 'react';
import tw from 'twin.macro';

interface ModulesDescriptionProps {
  heading: string;
  subHeading: string;
}
const ComponentWrapper = tw.div`
mt-[40px] lg:mt-[60px] mb-0 lg:mb-7
`;
const Heading = tw.p`
border-l-2 border-l-accent_blue_2 pl-6 text-[16px] lg:text-[20px] font-[500] leading-[24px] lg:leading-[30px] text-hubble_blue mb-2
`;
const SubHeadingWrapper = tw.p`
pl-6 whitespace-pre-line text-[14px] lg:text-[18px] leading-[21px] lg:leading-[27px]
`;

const ModulesDescription = ({
  heading,
  subHeading,
}: ModulesDescriptionProps) => {
  return (
    <ComponentWrapper>
      <Heading>{heading}</Heading>
      <SubHeadingWrapper>{subHeading}</SubHeadingWrapper>
    </ComponentWrapper>
  );
};

export default ModulesDescription;
