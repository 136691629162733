import { Modal } from 'antd';
import OutlinedBtn from 'components/requestForDemo/components/OutlinedBtn';
import React, { useState } from 'react';
import tw from 'twin.macro';
import ModulesCard from './ModulesCard';
import ModulesDescription from './ModulesDescription';
import { ProductOverviewSystemsItemTypeV2 } from '../types';

interface MobileOverviewSystemsProps {
  systemList: ProductOverviewSystemsItemTypeV2[];
}

const CloseModalBtnWrapper = tw.span`
  flex flex-row gap-3 text-accent_blue_2 items-center mr-[45px] pt-5
`;

const ListWrapper = tw.div`
lg:hidden px-[30px] flex flex-col gap-4 pb-[60px]
`;
const MobileOverviewSystems = ({ systemList }: MobileOverviewSystemsProps) => {
  const [showModal, setShowModal] = useState<{
    isOpen: boolean;
    currentModal: ProductOverviewSystemsItemTypeV2;
  }>({
    isOpen: false,
    currentModal: systemList[0],
  });

  const {
    imgSrc,
    imgAlt,
    name,
    heading,
    subHeading,
    moduleList,
    to,
    btnLabel,
  } = showModal.currentModal;
  return (
    <>
      <ListWrapper>
        {systemList.map(card => (
          <button
            key={card.id}
            onClick={() => {
              setShowModal({
                isOpen: true,
                currentModal: card,
              });
            }}
          >
            <ModulesCard
              name={card.name}
              moduleList={card.moduleList}
              isModal={false}
            />
          </button>
        ))}
      </ListWrapper>

      <Modal
        className="product-safety-benefit-modal"
        title={null}
        footer={null}
        open={showModal.isOpen}
        closeIcon={
          <CloseModalBtnWrapper>
            <span className="font-[500] text-[16px]">Close</span>
            <span className="fa-light fa-xmark text-[14px]" />
          </CloseModalBtnWrapper>
        }
        style={{
          top: '10vh',
          bottom: 0,
          minHeight: '90vh',
          minWidth: '100vw',
        }}
        bodyStyle={{ padding: '72px 30px 60px 30px', background: '#F8F9FD' }}
        onCancel={() => setShowModal(prev => ({ ...prev, isOpen: false }))}
      >
        <img alt={imgAlt} src={imgSrc} className="max-h-[310px] mx-auto" />
        <ModulesCard name={name} moduleList={moduleList} isModal={true} />
        <div className="h-1" />
        <OutlinedBtn label={btnLabel} to={to} />
        <ModulesDescription heading={heading} subHeading={subHeading} />
      </Modal>
    </>
  );
};

export default MobileOverviewSystems;
