import LanguageProvider from 'LanguageProvider/index';
import LayoutContainer from 'components/LayoutContainer';
import React from 'react';
import translation from './translation';
import ProductHero from './ProductHero';
import ProductBanner from './ProductBanner';
import OverviewProduct from './OverviewProduct';
import MobileOverviewSystems from './Systems/MobileOverviewSystems';
import WebOverviewSystems from './Systems/WebOverviewSystems';
import ProductBenefits from './ProductBenefits';
import {
  ProductOverviewBannerType,
  ProductOverviewBenefitsContentType,
  ProductOverviewHeroType,
  ProductOverviewMainHeroType,
  ProductOverviewSystemsItemTypeV2,
  ProductOverviewSystemsType,
} from './types';
import { NewsroomTestimonialContentType } from 'components/testimonial/types';
import NewsroomTestimonialScrollV2 from 'components/testimonial/newsroom/NewsroomTestimonialScrollV2';
import RequestForDemoCard from 'components/requestForDemo/RequestForDemoCard';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'react-intl';
import { createProductPageStructuredData } from 'components/structuredData/structuredDataUtils';
import StructuredDataScript from 'components/structuredData/StructuredDataScript';

interface ProductOverviewProps {
  pageUrl: string;
}
const ProductOverview = ({ pageUrl }: ProductOverviewProps) => {
  const { formatMessage } = useIntl();

  const productOverviewdata = useStaticQuery(graphql`
    query {
      allStrapiProductOverview {
        nodes {
          heroMainBannerHeader
          pageIcon {
            alternativeText
            url
          }
          pageLabel
          HeroMainBannerDescription
          heroMainBannerImage {
            alternativeText
            url
          }
          HeroFooterBannerDescription
          HeroFooterBannerHeader
          HeroFooterBannerCTAButtonLink
          HeroFooterBannerCTAButtonLabel
          ProductOverviewHeader
          ProductOverviewDescription
          ProcessValueChainDescription {
            name
          }
          SpecificProductCard {
            id
            icon {
              url
            }
            Only_For_DEV_labelClassName
            moreInfoButtonLabel1
            moreInfoButtonLink1
            name
            shortDescription
            status
          }
          SpecificProductSystem {
            id
            image {
              alternativeText
              url
            }
            label
            longDescription
            moduleName {
              name
            }
            moreInfoButtonLabel2
            moreInfoButtonLink2
            tagline
          }
          UniqueSellingPropositionHeader
          UniqueSellingPropositionItem {
            description
            header
          }
          CollateralReferencesHeader
          CollateralReferencesDescription
          CollateralReferencesCTAButtonLink
          CollateralReferencesCTAButtonLabel
          LeaveAReviewCTAButton
          HeroMainBannerCTAButtonLabel
          HeroMainBannerCTAButtonLink
          CustomerCard {
            image {
              alternativeText
              url
            }
            name
            testimonial
            title
          }
          pageCard {
            PageCTADescription
            PageCTAEmphaticDescription
            PageCTAHeader
            image {
              alternativeText
              url
            }
            CTAButton {
              label
              link
              type
            }
          }
          ProductStructuredData {
            name
            description
            ratingValue
            bestRating
            reviewCount
          }
          seo {
            metaTitle
            metaDescription
          }
        }
      }
    }
  `);
  const {
    pageIcon,
    heroMainBannerHeader,
    pageLabel,
    HeroMainBannerDescription,
    heroMainBannerImage,
    HeroFooterBannerDescription,
    HeroFooterBannerHeader,
    HeroFooterBannerCTAButtonLink,
    HeroFooterBannerCTAButtonLabel,
    ProductOverviewHeader,
    ProductOverviewDescription,
    ProcessValueChainDescription,
    SpecificProductCard,
    SpecificProductSystem,
    UniqueSellingPropositionHeader,
    UniqueSellingPropositionItem,
    CollateralReferencesHeader,
    CollateralReferencesDescription,
    CollateralReferencesCTAButtonLabel,
    CollateralReferencesCTAButtonLink,
    LeaveAReviewCTAButton,
    CustomerCard,
    pageCard: {
      PageCTADescription,
      PageCTAEmphaticDescription,
      PageCTAHeader,
      CTAButton,
      image: pageCardImg,
    },
    ProductStructuredData,
    seo: { metaTitle, metaDescription },
  } = productOverviewdata.allStrapiProductOverview.nodes[0];

  const heroContent: ProductOverviewMainHeroType = {
    icon: pageIcon.url,
    iconAlt: pageIcon.alternativeText,
    heroImg: heroMainBannerImage.url,
    heroImgAlt: heroMainBannerImage.alternativeText,
    pageLabel,
    heading: heroMainBannerHeader,
    subHeading: HeroMainBannerDescription,
  };
  const bannerContent: ProductOverviewBannerType = {
    header: HeroFooterBannerHeader,
    description: HeroFooterBannerDescription,
    btnLabel: HeroFooterBannerCTAButtonLabel,
    btnLink: HeroFooterBannerCTAButtonLink,
  };
  const productOverviewHero: ProductOverviewHeroType = {
    header: ProductOverviewHeader,
    description: ProductOverviewDescription,
  };
  const productOverviewSystems: ProductOverviewSystemsType = {
    labelList: ProcessValueChainDescription.map(
      ({ name }: { name: string }) => name,
    ),
    cardList: SpecificProductCard.map(
      ({
        Only_For_DEV_labelClassName,
        icon,
        name,
        status,
        shortDescription,
        moreInfoButtonLabel1,
        moreInfoButtonLink1,
      }: Record<string, any>) => ({
        label: name,
        icon: icon.url,
        isComingSoon: Boolean(status),
        labelClassName: Only_For_DEV_labelClassName,
        description: shortDescription,
        status,
        btnLink: moreInfoButtonLink1,
        btnLabel: moreInfoButtonLabel1,
        to: null,
      }),
    ),
  };
  const systemList: ProductOverviewSystemsItemTypeV2[] =
    SpecificProductSystem.map(
      ({
        id,
        image,
        label,
        tagline,
        longDescription,
        moreInfoButtonLink2,
        moreInfoButtonLabel2,
        moduleName,
      }: Record<string, any>) => ({
        id,
        name: label,
        heading: tagline,
        subHeading: longDescription,
        imgSrc: image.url,
        imgAlt: image.alternativeText,
        to: moreInfoButtonLink2,
        btnLabel: moreInfoButtonLabel2,
        moduleList: moduleName.map(({ name }: { name: string }) => name),
      }),
    );
  const benefitsContent: ProductOverviewBenefitsContentType = {
    header: UniqueSellingPropositionHeader,
    list: UniqueSellingPropositionItem.map(
      ({ header, description }: { header: string; description: string }) => ({
        heading: header,
        description,
      }),
    ),
  };
  const newsroomTestimonialContent: NewsroomTestimonialContentType = {
    title: CollateralReferencesHeader,
    description: CollateralReferencesDescription,
    btnLabel: CollateralReferencesCTAButtonLabel,
    btnLink: CollateralReferencesCTAButtonLink,
    reviewBtnLabel: LeaveAReviewCTAButton,
    cardList: CustomerCard.map(
      ({ name, title, testimonial, image }: Record<string, any>) => ({
        imgUrl: image.url,
        imgAlt: image.alternativeText,
        author: name,
        projectName: title,
        content: testimonial,
      }),
    ),
  };
  const requestForDemoCardContent = {
    title: PageCTAHeader,
    strongDescription: PageCTAEmphaticDescription,
    description: PageCTADescription,
    navBtn: CTAButton.map(({ label, link, type }: Record<string, string>) => ({
      label,
      to: link,
      type,
    })),
    imgUrl: pageCardImg.url,
    imgAlt: pageCardImg.alternativeText,
  };

  const structuredData = createProductPageStructuredData(
    pageUrl,
    metaTitle,
    metaDescription,
    pageLabel,
    ProductStructuredData,
    formatMessage,
  );

  return (
    <LanguageProvider messages={translation}>
      <StructuredDataScript data={structuredData} />
      <LayoutContainer>
        <ProductHero {...heroContent} />
      </LayoutContainer>
      <div className="bg-accent_blue_2">
        <LayoutContainer>
          <ProductBanner {...bannerContent} />
        </LayoutContainer>
      </div>
      <div className="lg:bg-accent_grey_1">
        <LayoutContainer>
          <OverviewProduct
            {...productOverviewHero}
            productOverviewSystems={productOverviewSystems}
          />
          <MobileOverviewSystems systemList={systemList} />
        </LayoutContainer>
      </div>
      <LayoutContainer>
        <WebOverviewSystems systemList={systemList} />
        <ProductBenefits {...benefitsContent} />
        <NewsroomTestimonialScrollV2 {...newsroomTestimonialContent} />
        <RequestForDemoCard {...requestForDemoCardContent} />
      </LayoutContainer>
    </LanguageProvider>
  );
};

export default ProductOverview;
