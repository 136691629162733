import React from 'react';
import ReviewBtnList from '../components/ReviewBtnList';
import ScrollCarousel from '../components/ScrollCarousel';
import ReviewCard from '../components/ReviewCard';
import LinkBtn from '../components/LinkBtn';
import MobileScrollCarousel from '../components/MobileScrollCarousel';
import { NewsroomTestimonialContentType } from '../types';

interface NewsroomTestimonialScrollProps
  extends NewsroomTestimonialContentType {}

const NewsroomTestimonialScrollV2 = ({
  title,
  description,
  btnLabel,
  btnLink,
  reviewBtnLabel,
  cardList,
}: NewsroomTestimonialScrollProps) => {
  return (
    <div className="md:h-[600px] flex flex-col md:flex-row md:gap-10 gap-16 my-[60px] mx-[30px] lg:mb-0 lg:mx-[0px] md:mr-0 md:ml-[70px] flex-nowrap">
      <div className="flex flex-col gap-6 md:gap-5 flex-shrink-0">
        <h2 className="m-0 md:w-[375px] text-base_text">{title}</h2>
        <p className="b1 m-0 md:w-[375px] text-subtext">{description}</p>
        <LinkBtn to={btnLink} label={btnLabel} />
        <ReviewBtnList reviewBtnLabel={reviewBtnLabel} />
      </div>
      <ScrollCarousel>
        {cardList.map((card, index) => (
          <div
            key={index}
            className="lg:pb-3 pt-0 pb-14 w-[325px] md:w-[565px]"
          >
            <ReviewCard {...card} />
          </div>
        ))}
      </ScrollCarousel>
      <MobileScrollCarousel>
        {cardList.map((card, index) => (
          <div
            key={index}
            className="lg:pb-3 pt-0 pb-14 w-[325px] md:w-[565px]"
          >
            <ReviewCard {...card} />
          </div>
        ))}
      </MobileScrollCarousel>
    </div>
  );
};

export default NewsroomTestimonialScrollV2;
