import React from 'react';
import YellowBtn from 'components/requestForDemo/components/YellowBtn';
import tw from 'twin.macro';
import { ProductOverviewMainHeroType } from './types';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageGraphQLType } from 'components/types';

interface ProductHeroProps extends ProductOverviewMainHeroType {}

const ComponentWrapper = tw.div`
flex flex-col items-center justify-center text-center px-[30px] lg:px-0 mt-[144px] lg:mt-[120px] pb-[40px]
`;
const IconAndLabelWrapper = tw.div`
flex gap-4 items-center lg:mb-4
`;
const Heading = tw.h1`
text-[36px] !font-[600] text-base_text mb-2 lg:text-[48px] lg:!font-[500] lg:text-accent_blue_1 lg:mb-4
`;
const SubHeading = tw.h2`
font-[400] text-[14px] text-base_text mb-6 whitespace-pre-line lg:text-[18px] lg:text-accent_blue_1 lg:mb-7 max-w-[740px]
`;
const HeroImg = tw.img`
my-6 max-h-[194px] md:max-h-[615px] object-contain
`;
const CompanyLogoWrapper = tw.div`
flex gap-5 md:gap-[40px] flex-wrap items-center justify-center
`;
const LogoImg = tw.img`
h-[20px] md:h-[35px] md:max-h-[615px] object-contain
`;

const ProductHero = ({
  icon,
  iconAlt,
  heroImg,
  heroImgAlt,
  pageLabel,
  heading,
  subHeading,
}: ProductHeroProps) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiProductOverview {
        nodes {
          HeroImageFooterBannerCustomerLogos {
            url
            alternativeText
          }
          HeroMainBannerCTAButtonLabel
          HeroMainBannerCTAButtonLink
        }
      }
    }
  `);
  const {
    HeroImageFooterBannerCustomerLogos,
    HeroMainBannerCTAButtonLabel,
    HeroMainBannerCTAButtonLink,
  } = data.allStrapiProductOverview.nodes[0];
  return (
    <ComponentWrapper>
      <IconAndLabelWrapper>
        <img src={icon} alt={iconAlt} className="h-[42px] w-[42px]" />
        <span className="text-[16px] lg:text-[18px] lg:font-[500] text-hubble_blue lg:text-accent_blue_1 leading-[24px]">
          {pageLabel}
        </span>
      </IconAndLabelWrapper>
      <Heading>{heading}</Heading>
      <SubHeading>{subHeading}</SubHeading>
      <YellowBtn
        to={HeroMainBannerCTAButtonLink}
        label={HeroMainBannerCTAButtonLabel}
      />
      <HeroImg src={heroImg} alt={heroImgAlt} />
      <CompanyLogoWrapper>
        {HeroImageFooterBannerCustomerLogos.map(
          ({ url, alternativeText }: ImageGraphQLType) => (
            <LogoImg key={url} src={url} alt={alternativeText} />
          ),
        )}
      </CompanyLogoWrapper>
    </ComponentWrapper>
  );
};

export default ProductHero;
