import React from 'react';
import checkIcon from 'images/check-icon.svg';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';

interface ModulesCardProps {
  name: string;
  moduleList: string[];
  isModal: boolean;
}
interface ModuleItemType {
  name: string;
}

const ModuleItemWrapper = tw.div`
flex flex-row items-center gap-4 lg:gap-[18px]
`;
const ModuleItemName = tw.span`
text-dark_black text-[14px] leading-[21px] lg:font-[500]
`;
const NameAndChevronWrapper = tw.div`
flex flex-row justify-between items-center text-hubble_blue
`;
const CardName = tw.span`
font-[500] text-[24px] leading-[32px] lg:text-[36px] lg:font-[600] lg:leading-[54px]
`;
const CardText = tw.p`
text-[16px] lg:font-[500] text-hubble_blue mb-4 leading-[24px]
`;
const ModuleListWrapper = tw.div`
grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-2
`;

const ModuleItem = ({ name }: ModuleItemType) => {
  return (
    <ModuleItemWrapper>
      <img src={checkIcon} className="w-[16px] h-[16px] " />
      <ModuleItemName>{name}</ModuleItemName>
    </ModuleItemWrapper>
  );
};

const ModulesCard = ({ name, moduleList, isModal }: ModulesCardProps) => {
  const { formatMessage } = useIntl();
  const wrapperCss = isModal ? '' : 'px-[42px]';
  return (
    <div className={`py-[24px] bg-accent_grey_1 text-start ${wrapperCss}`}>
      <NameAndChevronWrapper>
        <CardName>{name}</CardName>
        {!isModal && (
          <span className="lg:!hidden fa-regular fa-chevron-right py-[6px] py-3 text-[14px]" />
        )}
      </NameAndChevronWrapper>

      <CardText>
        {formatMessage({ id: 'productOverview.systems.moduleAvailable' })}
      </CardText>

      <ModuleListWrapper>
        {moduleList?.map(module => (
          <ModuleItem key={module} name={module} />
        ))}
      </ModuleListWrapper>
    </div>
  );
};

export default ModulesCard;
