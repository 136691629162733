import OutlinedBtn from 'components/requestForDemo/components/OutlinedBtn';
import React from 'react';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';
import { ProductOverviewSystemsItemTypeV2 } from '../types';
import ModulesCard from './ModulesCard';
import ModulesDescription from './ModulesDescription';

interface WebOverviewSystemsProps {
  systemList: ProductOverviewSystemsItemTypeV2[];
}

const SystemsItemWrapper = tw.div`
py-[120px] grid grid-cols-2 gap-[40px] items-center
`;

const WebOverviewSystems = ({ systemList }: WebOverviewSystemsProps) => {
  return (
    <div className="hidden lg:block">
      {systemList.map(
        ({
          imgSrc,
          imgAlt,
          name,
          heading,
          subHeading,
          moduleList,
          to,
          btnLabel,
        }) => (
          <SystemsItemWrapper key={name}>
            <div>
              <ModulesCard
                name={name}
                moduleList={moduleList}
                isModal={false}
              />
              <ModulesDescription heading={heading} subHeading={subHeading} />
              <div className="pl-6">
                <OutlinedBtn label={btnLabel} to={to} />
              </div>
            </div>
            <img alt={imgAlt} src={imgSrc} className="h-full object-contain" />
          </SystemsItemWrapper>
        ),
      )}
    </div>
  );
};

export default WebOverviewSystems;
