import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface ScrollCarouselProps {
  children: React.ReactNode;
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 200,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};
const CustomDot = ({ ...rest }) => {
  const { active, onClick } = rest;
  return (
    <button
      className={
        active ? 'text-hubble_blue md:hidden ' : 'text-[#D9D9D9] md:hidden '
      }
      onClick={() => onClick()}
    >
      <span className="fa-regular fa-minus fa-2x"></span>
    </button>
  );
};

const ScrollCarousel = ({ children }: ScrollCarouselProps) => {
  return (
    <div className="hidden md:block md:w-[800px] relative">
      <Carousel
        ssr
        partialVisible
        responsive={responsive}
        infinite={true}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        customLeftArrow={<></>}
        renderDotsOutside
        showDots
        customDot={<CustomDot />}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default ScrollCarousel;
