import React from 'react';

export interface ReviewCardProps {
  imgUrl: string;
  imgAlt: string | undefined;
  author: string;
  projectName: string;
  content: string;
}

const ReviewCard = ({
  imgUrl,
  imgAlt,
  author,
  projectName,
  content,
}: ReviewCardProps) => {
  return (
    <div className="bg-white flex-shrink-0 w-[270px] h-[650px] md:h-fit md:w-[558px] rounded-[10px] shadow-[0px_6px_10px_rgba(41,45,55,0.15)]">
      <img
        src={imgUrl}
        alt={imgAlt}
        className="w-full max-h-[195px] block rounded-t-[10px]"
      />
      <div className="flex flex-col gap-6 p-6">
        <div>
          <p className="b1 italic m-0 ">{author}</p>
          <h4 className=" m-0 text-subtext ">{projectName}</h4>
        </div>
        <p className="text-[16px] m-0 text-base_text">{content}</p>
      </div>
    </div>
  );
};

export default ReviewCard;
