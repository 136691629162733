import { ProductBenefitBrochuresType } from 'components/productTemplate/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';
import ProductOverviewDownloadBtn from '../landing/productOverview/ProductOverviewDownloadBtn';
import ProductsV2 from '../landing/productOverview/ProductsV2';
import { ProductOverviewHeroType, ProductOverviewSystemsType } from './types';

interface OverviewProductProps extends ProductOverviewHeroType {
  productOverviewSystems: ProductOverviewSystemsType;
}

const ComponentWrapper = tw.div`
pt-[40px] pb-6 lg:pt-[120px] lg:pb-[60px]
`;
const HeroWrapper = tw.div`
px-[30px] lg:px-0 flex flex-col items-center lg:items-start mb-4 lg:mb-[48px] text-center lg:text-start text-base_text
`;
const Heading = tw.p`
text-[24px] lg:text-[36px] font-[500] lg:font-[400] leading-[36px] mb-2 lg:mb-0 lg:leading-[54px]
`;
const Description = tw.p`
text-[14px] lg:text-[18px] leading-[21px] lg:leading-[27px] mb-4 lg:mb-6
`;
const OverviewProduct = ({
  header,
  description,
  productOverviewSystems,
}: OverviewProductProps) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiBrochure(filter: { isProductOverviewBrochure: { eq: true } }) {
        nodes {
          image {
            url
          }
          downloadLink
          brochureName
          dataLayer
        }
      }
    }
  `);
  const { downloadLink, brochureName, dataLayer, image } =
    data?.allStrapiBrochure?.nodes?.[0];

  const brochureContent: ProductBenefitBrochuresType = {
    brochureLink: downloadLink,
    brochureName,
    dataLayer,
    brochureImg: image.url,
  };
  return (
    <ComponentWrapper>
      <HeroWrapper>
        <Heading>{header}</Heading>
        <Description>{description}</Description>
        <ProductOverviewDownloadBtn brochureContent={brochureContent} />
      </HeroWrapper>

      <ProductsV2 {...productOverviewSystems} />
    </ComponentWrapper>
  );
};

export default OverviewProduct;
